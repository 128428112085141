<template>
    <div class="pageContol">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                  <em>当前位置：</em>
                  <a href="javascript:;">考务管理</a>
                  <i>></i>
                  <a href="javascript:;" class="cur-a" @click="$router.back()">考场安排</a>
                  <i>></i>
                  <a href="javascript:;" class="cur-a">手动分配考场</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start">
                    <div class="searchbox" style="margin-bottom: 15px">
                        <el-button class="bgc-bv" style="margin: 0 10px" @click="updateRoom()">新增</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
                                  style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
                            <el-table-column label="考场名称" align="center" prop="name" show-overflow-tooltip min-width="160px" />

                            <el-table-column label="考场人数" align="center" prop="maxNum" show-overflow-tooltip min-width="120px" />
                            <el-table-column label="当前考场人数" align="center" prop="currentNum" show-overflow-tooltip min-width="260px" />
                            <el-table-column label="准考证规则" align="center" prop="candidateNo" show-overflow-tooltip min-width="160px" />

                            <el-table-column label="操作" width="120px" align="center" fixed="right">
                                <template v-slot="{ row }">
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                               @click="updateRoom(row.classroomId)">修改</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                               @click="deleteRoom(row.classroomId)">删除</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>

    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
    name: "examinationRoomArrangement_manualAssignmentExaminationRoom",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            activityId: "", // 考务id(路由传过来的)
            activityName: "",
            occupationName: "",
            levelCodeName: "",
            // 检索数据
            searchForm: {
                userName: "", // 姓名
                mobile: "", // 手机号
                classroomId:"", // 考场
            },
        };
    },
    created() {
        this.activityId = this.$route.query.activityId;
        this.activityName = this.$route.query.activityName;
        this.occupationName = this.$route.query.occupationName;
        this.levelCodeName = this.$route.query.levelCodeName;

    },
    computed: {
        ...mapGetters({
            downloadItems: "getDownloadItems",
        }),
    },
    methods: {
        // 获取列表数据
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                activityId: this.$route.query.activityId,
            };
            this.doFetch(
                {
                    url: "/biz/examination_affairs/arrangement/handClassroomList",
                    params,
                    pageNum,
                },
                true,
                6
            );
        },
        // 修改考场
        setExaminationHall(row) {
            this.examinationHallData.userName = row.userName;
            this.examinationHallData.name = row.name;
            this.examinationHallData.seatNumber = row.seatNumber;
            this.examinationHallData.yclassroomUserId = row.classroomUserId;
            this.examinationHallData.dialogVisible = true;
        },
        // 添加考场
        updateRoom(classroomId){
            this.$router.push({
                path: '/web/appraisalInstitution/examinationRoomArrangement/manualAssignmentUpdate',
                query: {
                    classroomId: classroomId||null,
                    activityId: this.activityId,
                    activityName: this.activityName,
                    occupationName: this.occupationName,
                    levelCodeName: this.levelCodeName
                },

            })
        },
        deleteRoom(classroomId){
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$post("/biz/examination_affairs/arrangement/deleteApportionHand", {
                    classroomId: classroomId,
                    activityId: this.activityId,
                },3000,true,6).then(() => {
                    this.$message.success('删除成功！')
                    this.getData(-1)
                });
            });
        }
    },
};
</script>
<style lang="less" scoped>
.examinationHallDataTs {
  color: red;
}
</style>